.pagination-wrapper {
  // position: absolute;
  // right: 10%;
  // bottom: 4%;
  margin-left: auto;
  margin-right: 0;
  margin-top: 20px;
}

.download-container {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bcal-table {
  width: 100%;
  // height: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.6);
  th.ant-table-cell {
    // @include linear-gradient('to bottom', (#404040, #060606));
    background: #009688;
    color: black;
    // min-width: 200px;
    vertical-align: middle;
  }
  th.ant-table-column-has-sorters:hover {
    // @include linear-gradient('to bottom', (#404040, #060606));
    background: #009688;
    color: black;
  }
  .ant-table-body {
    background-color: white;
  }
  .ant-table {
    background-color: #efeeee;
  }
  td.ant-table-column-sort {
    background-color: white;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px 5px;
  }

  td.ant-table-cell {
    vertical-align: middle;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-table-fixed {
    table-layout: fixed;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
    font-weight: 400;
    font-size: 16px;
  }
}

.rowColoringEven td {
  background-color: #a5d6a7;
}

.rowColoringOdd td {
  background-color: #ffe082;
}

.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}
.virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}
[data-theme="dark"] .virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #303030;
  background: #141414;
}

.action-button {
  border: 0.5px solid black;
  cursor: pointer;
  padding: 0 15px;
}

.virtual-table-cell-last {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.tablegrid__cellhovered {
  background: white !important;
}
