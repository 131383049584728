.dashboard-container {
  height: calc(100vh - 70px);
  width: 100vw;
  padding: 10px 20px 20px 20px;
}

@media only screen and (min-width: 600px) {
  .dashboard-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    height: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    height: 120px;
  }
}

.dashboard-search-helper-text {
  margin: 0 10px 0 10px;
}

.rowClassName1 {
  background-color: cadetblue;
  border-radius: 10px;
  border-color: chartreuse;
  border-width: 3px;
  border-style: solid;
}

.rowClassName1 td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rowClassName1 td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.date-range-picker {
  margin-bottom: 40px;
}

.top-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.create-button {
  width: 100px;
  height: 40px;
  border: 1px solid;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  margin-left: auto;
}

.instructions {
  display: flex;
  width: 250px;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.add-client-button {
  width: 100px;
  height: 30px;
  /* background: black; */
  margin: auto 20px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px grey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #388e3c;
  color: white;
}

.dashboard-password-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1em;
  justify-content: center;
  align-items: center;
  padding: 2em;
}
