.bike-container{
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.bike-info-container{
    font-size: 14px;
    width: 100%;
    height: 250px;
    background: pink;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bike-reservations-container{
    padding: 40px;
    width: 100%;
    display: flex;
    font-size: 14px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}