.login-container{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-input{
    margin: 10px 0;
    width: 300px;
}

.login-button{
    margin: 10px 0;
    width: 300px;
}

.login-navbar{
    height: 70px;
    width: 100%;
    top: 0;
    background-image: linear-gradient(to right top, #388e3c, #4ea53a, #69bd35, #87d42a, #a8eb12);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    margin: 0 0 20px 0;
}

.create-account{
    position: absolute;
    right: 0;
    margin-right: 50px;
    font-size: 16px;
    cursor: pointer;
}
