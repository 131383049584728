@media only screen and (min-width: 600px) {
    .navbar-container{
        display: flex;
        height: 70px;
        width: 100%;
        /* background-color: #388E3C; */
        color: white;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(to right top, #388e3c, #4ea53a, #69bd35, #87d42a, #a8eb12);
    }
    
    .navbar-link{
        height: 100%;
        padding: 0 2em;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-size: 1.2em;
    }
    
    .left-links{
        left: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
    }
    
    .right-links{
        right: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-right: 20px;
    }
    
    .header{
        font-size: 28px;
        font-weight: 400;
        cursor: pointer;
    }
}

@media only screen and (max-width: 600px) {
    .navbar-container{
        display: flex;
        height: 80px;
        width: 100%;
        /* background-color: #388E3C; */
        color: white;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-image: linear-gradient(to right top, #388e3c, #4ea53a, #69bd35, #87d42a, #a8eb12);
        padding: 2em;
    }

    .header{
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
    }
}
